@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@layer components {
    .title-line-xl {
        background: #333;
        content: " ";
        height: 3px;
        width: 120px;
        margin: 15px 10px;
        display: inline-block;
    }

    .title-line-lg {
      background: #333;
      content: " ";
      height: 3px;
      width: 120px;
      margin: 10px 10px;
      display: inline-block;
  }

    .section-padding {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .h-screen-50 {
      height: 50vh;
    }

    .h-screen-75 {
      height: 75vh;
    }

    .h-screen-80 {
      height: 80vh;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-6 13:50:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
 .scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }